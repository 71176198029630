// General
export { default as IconArrowBlack } from '~/static/icons/general/arrow/arrow-black.svg';
export { default as IconArrowWhite } from '~/static/icons/general/arrow/arrow-white.svg';
export { default as IconBagBlack } from '~/static/icons/general/bag/bag-black.svg';
export { default as IconBagWhite } from '~/static/icons/general/bag/bag-white.svg';
export { default as IconCloseBlack } from '~/static/icons/general/close/close-black.svg';
export { default as IconCloseWhite } from '~/static/icons/general/close/close-white.svg';
export { default as IconEyeBlack } from '~/static/icons/general/eye/eye-black.svg';
export { default as IconEyeWhite } from '~/static/icons/general/eye/eye-white.svg';
export { default as IconEyeInactiveBlack } from '~/static/icons/general/eye/eye-inactive-black.svg';
export { default as IconEyeInactiveWhite } from '~/static/icons/general/eye/eye-inactive-white.svg';
export { default as IconFileBlack } from '~/static/icons/general/file/file-black.svg';
export { default as IconFileWhite } from '~/static/icons/general/file/file-white.svg';
export { default as IconGoBackBlack } from '~/static/icons/general/go-back/go-back-black.svg';
export { default as IconGoBackWhite } from '~/static/icons/general/go-back/go-back-white.svg';
export { default as IconHandmadeBlack } from '~/static/icons/general/handmade/handmade-black.svg';
export { default as IconHandmadeWhite } from '~/static/icons/general/handmade/handmade-white.svg';
export { default as IconHeartBlackFull } from '~/static/icons/general/heart/heart-black-fill.svg';
export { default as IconHeartWhiteFull } from '~/static/icons/general/heart/heart-white-fill.svg';
export { default as IconHeartBlack } from '~/static/icons/general/heart/heart-black.svg';
export { default as IconHeartWhite } from '~/static/icons/general/heart/heart-white.svg';
export { default as IconMenuBlack } from '~/static/icons/general/menu/menu-black.svg';
export { default as IconMenuWhite } from '~/static/icons/general/menu/menu-white.svg';
export { default as IconMinusBlack } from '~/static/icons/general/minus/minus-black.svg';
export { default as IconMinusWhite } from '~/static/icons/general/minus/minus-white.svg';
export { default as IconMoreBlack } from '~/static/icons/general/more/more-black.svg';
export { default as IconMoreBlack1616 } from '~/static/icons/general/more/more-black-16x16.svg';
export { default as IconMoreDarkGray } from '~/static/icons/general/more/more-dark-gray.svg';
export { default as IconMoreWhite } from '~/static/icons/general/more/more-white.svg';
export { default as IconPlusBlack } from '~/static/icons/general/plus/plus-black.svg';
export { default as IconPlusWhite } from '~/static/icons/general/plus/plus-white.svg';
export { default as IconSearchBlack } from '~/static/icons/general/search/search-black.svg';
export { default as IconSearchWhite } from '~/static/icons/general/search/search-white.svg';
export { default as IconUserBlack } from '~/static/icons/general/user/user-black.svg';
export { default as IconUserWhite } from '~/static/icons/general/user/user-white.svg';
export { default as ChevronWhiteRight } from '~/static/icons/general/chevron/chevron_white_right.svg';
export { default as ChevronWhiteLeft } from '~/static/icons/general/chevron/chevron_white_left.svg';
export { default as ChevronBlackUp } from '~/static/icons/general/chevron/chevron_black_up.svg';
export { default as ChevronBlackDown } from '~/static/icons/general/chevron/chevron_black_down.svg';
export { default as ChevronGrayDown } from '~/static/icons/general/chevron/chevron_gray_down.svg';
export { default as Ruler } from '~/static/icons/general/ruler/ruler.svg';
export { default as SoundOn } from '~/static/icons/general/sound/sound-on.svg';
export { default as SoundOff } from '~/static/icons/general/sound/sound-off.svg';
export { default as LinkBlack } from '~/static/icons/general/link/link-black.svg';
export { default as LinkWhite } from '~/static/icons/general/link/link-white.svg';
export { default as ShareBlack } from '~/static/icons/general/share/share-black.svg';
export { default as ShareWhite } from '~/static/icons/general/share/share-white.svg';
// Social
export { default as SocialIconFacebookBlack } from '~/static/icons/social/facebook/facebook-black.svg';
export { default as SocialIconFacebookWhite } from '~/static/icons/social/facebook/facebook-white.svg';
export { default as SocialIconInstagramBlack } from '~/static/icons/social/instagram/instagram-black.svg';
export { default as SocialIconInstagramWhite } from '~/static/icons/social/instagram/instagram-white.svg';
export { default as SocialIconLinkedinBlack } from '~/static/icons/social/linkedin/linkedin-black.svg';
export { default as SocialIconLinkedinWhite } from '~/static/icons/social/linkedin/linkedin-white.svg';
export { default as SocialIconPinterestBlack } from '~/static/icons/social/pinterest/pinterest-black.svg';
export { default as SocialIconPinterestWhite } from '~/static/icons/social/pinterest/pinterest-white.svg';
export { default as SocialIconTiktokBlack } from '~/static/icons/social/tiktok/tiktok-black.svg';
export { default as SocialIconTiktokWhite } from '~/static/icons/social/tiktok/tiktok-white.svg';
export { default as SocialIconTwitterBlack } from '~/static/icons/social/twitter/twitter-black.svg';
export { default as SocialIconTwitterWhite } from '~/static/icons/social/twitter/twitter-white.svg';
export { default as SocialIconYoutubeBlack } from '~/static/icons/social/youtube/youtube-black.svg';
export { default as SocialIconYoutubeWhite } from '~/static/icons/social/youtube/youtube-white.svg';

export { default as SocialIconWhatsAppBlack } from '~/static/icons/social/whatsapp/whatsapp-black.svg';
export { default as SocialIconWhatsAppWhite } from '~/static/icons/social/whatsapp/whatsapp-white.svg';
// Cursors
export { default as CursorNextBlack } from '~/static/icons/cursors/next-black.svg';
export { default as CursorNextWhite } from '~/static/icons/cursors/next-white.svg';
// Vectors
export { default as PointRightBlack } from '~/static/icons/vectors/point-right-black.svg';
export { default as ArrowNext } from '~/static/icons/vectors/arrow-next.svg';
export { default as ArrowPrev } from '~/static/icons/vectors/arrow-prev.svg';
export { default as SmallArrowBlackRight } from '~/static/icons/vectors/small-arrow-black-right.svg';
export { default as SmallArrowBlackDown } from '~/static/icons/vectors/small-arrow-black-down.svg';
// Chat
export { default as ChatIcon } from '~/static/icons/chat/chat-icon.svg';
// Flags
/* export { default as FlagAC } from '~/static/icons/flags/ac.svg';
export { default as FlagAD } from '~/static/icons/flags/ad.svg';
export { default as FlagAE } from '~/static/icons/flags/ae.svg';
export { default as FlagAF } from '~/static/icons/flags/af.svg';
export { default as FlagAG } from '~/static/icons/flags/ag.svg';
export { default as FlagAI } from '~/static/icons/flags/ai.svg';
export { default as FlagAL } from '~/static/icons/flags/al.svg';
export { default as FlagAM } from '~/static/icons/flags/am.svg';
export { default as FlagAO } from '~/static/icons/flags/ao.svg';
export { default as FlagAQ } from '~/static/icons/flags/aq.svg';
export { default as FlagAR } from '~/static/icons/flags/ar.svg';
export { default as FlagAS } from '~/static/icons/flags/as.svg';
export { default as FlagAT } from '~/static/icons/flags/at.svg';
export { default as FlagAU } from '~/static/icons/flags/au.svg';
export { default as FlagAW } from '~/static/icons/flags/aw.svg';
export { default as FlagAX } from '~/static/icons/flags/ax.svg';
export { default as FlagAZ } from '~/static/icons/flags/az.svg';
export { default as FlagBA } from '~/static/icons/flags/ba.svg';
export { default as FlagBB } from '~/static/icons/flags/bb.svg';
export { default as FlagBD } from '~/static/icons/flags/bd.svg';
export { default as FlagBE } from '~/static/icons/flags/be.svg';
export { default as FlagBF } from '~/static/icons/flags/bf.svg';
export { default as FlagBG } from '~/static/icons/flags/bg.svg';
export { default as FlagBH } from '~/static/icons/flags/bh.svg';
export { default as FlagBI } from '~/static/icons/flags/bi.svg';
export { default as FlagBJ } from '~/static/icons/flags/bj.svg';
export { default as FlagBL } from '~/static/icons/flags/bl.svg';
export { default as FlagBM } from '~/static/icons/flags/bm.svg';
export { default as FlagBN } from '~/static/icons/flags/bn.svg';
export { default as FlagBO } from '~/static/icons/flags/bo.svg';
export { default as FlagBQ } from '~/static/icons/flags/bq.svg';
export { default as FlagBR } from '~/static/icons/flags/br.svg';
export { default as FlagBS } from '~/static/icons/flags/bs.svg';
export { default as FlagBT } from '~/static/icons/flags/bt.svg';
export { default as FlagBV } from '~/static/icons/flags/bv.svg';
export { default as FlagBW } from '~/static/icons/flags/bw.svg';
export { default as FlagBY } from '~/static/icons/flags/by.svg';
export { default as FlagBZ } from '~/static/icons/flags/bz.svg';
export { default as FlagCA } from '~/static/icons/flags/ca.svg';
export { default as FlagCC } from '~/static/icons/flags/cc.svg';
export { default as FlagCD } from '~/static/icons/flags/cd.svg';
export { default as FlagCF } from '~/static/icons/flags/cf.svg';
export { default as FlagCG } from '~/static/icons/flags/cg.svg';
export { default as FlagCH } from '~/static/icons/flags/ch.svg';
export { default as FlagCI } from '~/static/icons/flags/ci.svg';
export { default as FlagCK } from '~/static/icons/flags/ck.svg';
export { default as FlagCL } from '~/static/icons/flags/cl.svg';
export { default as FlagCM } from '~/static/icons/flags/cm.svg';
export { default as FlagCN } from '~/static/icons/flags/cn.svg';
export { default as FlagCO } from '~/static/icons/flags/co.svg';
export { default as FlagCP } from '~/static/icons/flags/cp.svg';
export { default as FlagCR } from '~/static/icons/flags/cr.svg';
export { default as FlagCU } from '~/static/icons/flags/cu.svg';
export { default as FlagCV } from '~/static/icons/flags/cv.svg';
export { default as FlagCW } from '~/static/icons/flags/cw.svg';
export { default as FlagCX } from '~/static/icons/flags/cx.svg';
export { default as FlagCY } from '~/static/icons/flags/cy.svg';
export { default as FlagCZ } from '~/static/icons/flags/cz.svg';
export { default as FlagDE } from '~/static/icons/flags/de.svg';
export { default as FlagDG } from '~/static/icons/flags/dg.svg';
export { default as FlagDJ } from '~/static/icons/flags/dj.svg';
export { default as FlagDK } from '~/static/icons/flags/dk.svg';
export { default as FlagDM } from '~/static/icons/flags/dm.svg';
export { default as FlagDO } from '~/static/icons/flags/do.svg';
export { default as FlagDZ } from '~/static/icons/flags/dz.svg';
export { default as FlagEA } from '~/static/icons/flags/ea.svg';
export { default as FlagEC } from '~/static/icons/flags/ec.svg';
export { default as FlagEE } from '~/static/icons/flags/ee.svg';
export { default as FlagEG } from '~/static/icons/flags/eg.svg';
export { default as FlagEH } from '~/static/icons/flags/eh.svg';
export { default as FlagER } from '~/static/icons/flags/er.svg';
export { default as FlagESCT } from '~/static/icons/flags/es-ct.svg';
export { default as FlagESGA } from '~/static/icons/flags/es-ga.svg';
export { default as FlagESPV } from '~/static/icons/flags/es-pv.svg';
export { default as FlagES } from '~/static/icons/flags/es.svg';
export { default as FlagET } from '~/static/icons/flags/et.svg';
export { default as FlagEU } from '~/static/icons/flags/eu.svg';
export { default as FlagFI } from '~/static/icons/flags/fi.svg';
export { default as FlagFJ } from '~/static/icons/flags/fj.svg';
export { default as FlagFK } from '~/static/icons/flags/fk.svg';
export { default as FlagFM } from '~/static/icons/flags/fm.svg';
export { default as FlagFO } from '~/static/icons/flags/fo.svg';
export { default as FlagFR } from '~/static/icons/flags/fr.svg';
export { default as FlagGA } from '~/static/icons/flags/ga.svg';
export { default as FlagGBENG } from '~/static/icons/flags/gb-eng.svg';
export { default as FlagGBNIR } from '~/static/icons/flags/gb-nir.svg';
export { default as FlagGBSCT } from '~/static/icons/flags/gb-sct.svg';
export { default as FlagGB } from '~/static/icons/flags/gb.svg';
export { default as FlagGBWLS } from '~/static/icons/flags/gb-wls.svg';
export { default as FlagGD } from '~/static/icons/flags/gd.svg';
export { default as FlagGE } from '~/static/icons/flags/ge.svg';
export { default as FlagGF } from '~/static/icons/flags/gf.svg';
export { default as FlagGG } from '~/static/icons/flags/gg.svg';
export { default as FlagGH } from '~/static/icons/flags/gh.svg';
export { default as FlagGI } from '~/static/icons/flags/gi.svg';
export { default as FlagGL } from '~/static/icons/flags/gl.svg';
export { default as FlagGM } from '~/static/icons/flags/gm.svg';
export { default as FlagGN } from '~/static/icons/flags/gn.svg';
export { default as FlagGP } from '~/static/icons/flags/gp.svg';
export { default as FlagGQ } from '~/static/icons/flags/gq.svg';
export { default as FlagGR } from '~/static/icons/flags/gr.svg';
export { default as FlagGS } from '~/static/icons/flags/gs.svg';
export { default as FlagGT } from '~/static/icons/flags/gt.svg';
export { default as FlagGU } from '~/static/icons/flags/gu.svg';
export { default as FlagGW } from '~/static/icons/flags/gw.svg';
export { default as FlagGY } from '~/static/icons/flags/gy.svg';
export { default as FlagHK } from '~/static/icons/flags/hk.svg';
export { default as FlagHM } from '~/static/icons/flags/hm.svg';
export { default as FlagHN } from '~/static/icons/flags/hn.svg';
export { default as FlagHR } from '~/static/icons/flags/hr.svg';
export { default as FlagHT } from '~/static/icons/flags/ht.svg';
export { default as FlagHU } from '~/static/icons/flags/hu.svg';
export { default as FlagIC } from '~/static/icons/flags/ic.svg';
export { default as FlagID } from '~/static/icons/flags/id.svg';
export { default as FlagIE } from '~/static/icons/flags/ie.svg';
export { default as FlagIL } from '~/static/icons/flags/il.svg';
export { default as FlagIM } from '~/static/icons/flags/im.svg';
export { default as FlagIN } from '~/static/icons/flags/in.svg';
export { default as FlagIO } from '~/static/icons/flags/io.svg';
export { default as FlagIQ } from '~/static/icons/flags/iq.svg';
export { default as FlagIR } from '~/static/icons/flags/ir.svg';
export { default as FlagIS } from '~/static/icons/flags/is.svg';
export { default as FlagIT } from '~/static/icons/flags/it.svg';
export { default as FlagJE } from '~/static/icons/flags/je.svg';
export { default as FlagJM } from '~/static/icons/flags/jm.svg';
export { default as FlagJO } from '~/static/icons/flags/jo.svg';
export { default as FlagJP } from '~/static/icons/flags/jp.svg';
export { default as FlagKE } from '~/static/icons/flags/ke.svg';
export { default as FlagKG } from '~/static/icons/flags/kg.svg';
export { default as FlagKH } from '~/static/icons/flags/kh.svg';
export { default as FlagKI } from '~/static/icons/flags/ki.svg';
export { default as FlagKM } from '~/static/icons/flags/km.svg';
export { default as FlagKN } from '~/static/icons/flags/kn.svg';
export { default as FlagKP } from '~/static/icons/flags/kp.svg';
export { default as FlagKR } from '~/static/icons/flags/kr.svg';
export { default as FlagKW } from '~/static/icons/flags/kw.svg';
export { default as FlagKY } from '~/static/icons/flags/ky.svg';
export { default as FlagKZ } from '~/static/icons/flags/kz.svg';
export { default as FlagLA } from '~/static/icons/flags/la.svg';
export { default as FlagLB } from '~/static/icons/flags/lb.svg';
export { default as FlagLC } from '~/static/icons/flags/lc.svg';
export { default as FlagLI } from '~/static/icons/flags/li.svg';
export { default as FlagLK } from '~/static/icons/flags/lk.svg';
export { default as FlagLR } from '~/static/icons/flags/lr.svg';
export { default as FlagLS } from '~/static/icons/flags/ls.svg';
export { default as FlagLT } from '~/static/icons/flags/lt.svg';
export { default as FlagLU } from '~/static/icons/flags/lu.svg';
export { default as FlagLV } from '~/static/icons/flags/lv.svg';
export { default as FlagLY } from '~/static/icons/flags/ly.svg';
export { default as FlagMA } from '~/static/icons/flags/ma.svg';
export { default as FlagMC } from '~/static/icons/flags/mc.svg';
export { default as FlagMD } from '~/static/icons/flags/md.svg';
export { default as FlagME } from '~/static/icons/flags/me.svg';
export { default as FlagMF } from '~/static/icons/flags/mf.svg';
export { default as FlagMG } from '~/static/icons/flags/mg.svg';
export { default as FlagMH } from '~/static/icons/flags/mh.svg';
export { default as FlagMK } from '~/static/icons/flags/mk.svg';
export { default as FlagML } from '~/static/icons/flags/ml.svg';
export { default as FlagMM } from '~/static/icons/flags/mm.svg';
export { default as FlagMN } from '~/static/icons/flags/mn.svg';
export { default as FlagMO } from '~/static/icons/flags/mo.svg';
export { default as FlagMP } from '~/static/icons/flags/mp.svg';
export { default as FlagMQ } from '~/static/icons/flags/mq.svg';
export { default as FlagMR } from '~/static/icons/flags/mr.svg';
export { default as FlagMS } from '~/static/icons/flags/ms.svg';
export { default as FlagMT } from '~/static/icons/flags/mt.svg';
export { default as FlagMU } from '~/static/icons/flags/mu.svg';
export { default as FlagMV } from '~/static/icons/flags/mv.svg';
export { default as FlagMW } from '~/static/icons/flags/mw.svg';
export { default as FlagMX } from '~/static/icons/flags/mx.svg';
export { default as FlagMY } from '~/static/icons/flags/my.svg';
export { default as FlagMZ } from '~/static/icons/flags/mz.svg';
export { default as FlagNA } from '~/static/icons/flags/na.svg';
export { default as FlagNC } from '~/static/icons/flags/nc.svg';
export { default as FlagNE } from '~/static/icons/flags/ne.svg';
export { default as FlagNF } from '~/static/icons/flags/nf.svg';
export { default as FlagNG } from '~/static/icons/flags/ng.svg';
export { default as FlagNI } from '~/static/icons/flags/ni.svg';
export { default as FlagNL } from '~/static/icons/flags/nl.svg';
export { default as FlagNO } from '~/static/icons/flags/no.svg';
export { default as FlagNP } from '~/static/icons/flags/np.svg';
export { default as FlagNR } from '~/static/icons/flags/nr.svg';
export { default as FlagNU } from '~/static/icons/flags/nu.svg';
export { default as FlagNZ } from '~/static/icons/flags/nz.svg';
export { default as FlagOM } from '~/static/icons/flags/om.svg';
export { default as FlagPA } from '~/static/icons/flags/pa.svg';
export { default as FlagPE } from '~/static/icons/flags/pe.svg';
export { default as FlagPF } from '~/static/icons/flags/pf.svg';
export { default as FlagPG } from '~/static/icons/flags/pg.svg';
export { default as FlagPH } from '~/static/icons/flags/ph.svg';
export { default as FlagPK } from '~/static/icons/flags/pk.svg';
export { default as FlagPL } from '~/static/icons/flags/pl.svg';
export { default as FlagPM } from '~/static/icons/flags/pm.svg';
export { default as FlagPN } from '~/static/icons/flags/pn.svg';
export { default as FlagPR } from '~/static/icons/flags/pr.svg';
export { default as FlagPS } from '~/static/icons/flags/ps.svg';
export { default as FlagPT } from '~/static/icons/flags/pt.svg';
export { default as FlagPW } from '~/static/icons/flags/pw.svg';
export { default as FlagPY } from '~/static/icons/flags/py.svg';
export { default as FlagQA } from '~/static/icons/flags/qa.svg';
export { default as FlagRE } from '~/static/icons/flags/re.svg';
export { default as FlagRO } from '~/static/icons/flags/ro.svg';
export { default as FlagRS } from '~/static/icons/flags/rs.svg';
export { default as FlagRU } from '~/static/icons/flags/ru.svg';
export { default as FlagRW } from '~/static/icons/flags/rw.svg';
export { default as FlagSA } from '~/static/icons/flags/sa.svg';
export { default as FlagSB } from '~/static/icons/flags/sb.svg';
export { default as FlagSC } from '~/static/icons/flags/sc.svg';
export { default as FlagSD } from '~/static/icons/flags/sd.svg';
export { default as FlagSE } from '~/static/icons/flags/se.svg';
export { default as FlagSG } from '~/static/icons/flags/sg.svg';
export { default as FlagSH } from '~/static/icons/flags/sh.svg';
export { default as FlagSI } from '~/static/icons/flags/si.svg';
export { default as FlagSJ } from '~/static/icons/flags/sj.svg';
export { default as FlagSK } from '~/static/icons/flags/sk.svg';
export { default as FlagSL } from '~/static/icons/flags/sl.svg';
export { default as FlagSM } from '~/static/icons/flags/sm.svg';
export { default as FlagSN } from '~/static/icons/flags/sn.svg';
export { default as FlagSO } from '~/static/icons/flags/so.svg';
export { default as FlagSR } from '~/static/icons/flags/sr.svg';
export { default as FlagSS } from '~/static/icons/flags/ss.svg';
export { default as FlagST } from '~/static/icons/flags/st.svg';
export { default as FlagSV } from '~/static/icons/flags/sv.svg';
export { default as FlagSX } from '~/static/icons/flags/sx.svg';
export { default as FlagSY } from '~/static/icons/flags/sy.svg';
export { default as FlagSZ } from '~/static/icons/flags/sz.svg';
export { default as FlagTA } from '~/static/icons/flags/ta.svg';
export { default as FlagTC } from '~/static/icons/flags/tc.svg';
export { default as FlagTD } from '~/static/icons/flags/td.svg';
export { default as FlagTF } from '~/static/icons/flags/tf.svg';
export { default as FlagTG } from '~/static/icons/flags/tg.svg';
export { default as FlagTH } from '~/static/icons/flags/th.svg';
export { default as FlagTJ } from '~/static/icons/flags/tj.svg';
export { default as FlagTK } from '~/static/icons/flags/tk.svg';
export { default as FlagTL } from '~/static/icons/flags/tl.svg';
export { default as FlagTM } from '~/static/icons/flags/tm.svg';
export { default as FlagTN } from '~/static/icons/flags/tn.svg';
export { default as FlagTO } from '~/static/icons/flags/to.svg';
export { default as FlagTR } from '~/static/icons/flags/tr.svg';
export { default as FlagTT } from '~/static/icons/flags/tt.svg';
export { default as FlagTV } from '~/static/icons/flags/tv.svg';
export { default as FlagTW } from '~/static/icons/flags/tw.svg';
export { default as FlagTZ } from '~/static/icons/flags/tz.svg';
export { default as FlagUA } from '~/static/icons/flags/ua.svg';
export { default as FlagUG } from '~/static/icons/flags/ug.svg';
export { default as FlagUM } from '~/static/icons/flags/um.svg';
export { default as FlagUN } from '~/static/icons/flags/un.svg';
export { default as FlagUS } from '~/static/icons/flags/us.svg';
export { default as FlagUY } from '~/static/icons/flags/uy.svg';
export { default as FlagUZ } from '~/static/icons/flags/uz.svg';
export { default as FlagVA } from '~/static/icons/flags/va.svg';
export { default as FlagVC } from '~/static/icons/flags/vc.svg';
export { default as FlagVE } from '~/static/icons/flags/ve.svg';
export { default as FlagVG } from '~/static/icons/flags/vg.svg';
export { default as FlagVI } from '~/static/icons/flags/vi.svg';
export { default as FlagVN } from '~/static/icons/flags/vn.svg';
export { default as FlagVU } from '~/static/icons/flags/vu.svg';
export { default as FlagWF } from '~/static/icons/flags/wf.svg';
export { default as FlagWS } from '~/static/icons/flags/ws.svg';
export { default as FlagXK } from '~/static/icons/flags/xk.svg';
export { default as FlagXX } from '~/static/icons/flags/xx.svg';
export { default as FlagYE } from '~/static/icons/flags/ye.svg';
export { default as FlagYT } from '~/static/icons/flags/yt.svg';
export { default as FlagZA } from '~/static/icons/flags/za.svg';
export { default as FlagZM } from '~/static/icons/flags/zm.svg';
export { default as FlagZW } from '~/static/icons/flags/zw.svg'; */
// PDP
export { default as ZoomButton } from '~/static/icons/pdp/zoom-button.svg';
export { default as BackButton } from '~/static/icons/pdp/back-button.svg';
export { default as SecurePaymentsIcon } from '~/static/icons/pdp/secure-payments-icon.svg';
export { default as FreeShippingIcon } from '~/static/icons/pdp/free-shipping-icon.svg';
export { default as InsuredExpressCourierIcon } from '~/static/icons/pdp/insured-express-courier-icon.svg';
export { default as GenuineHandmadeGlassIcon } from '~/static/icons/pdp/genuine-handmade-glass-icon.svg';
export { default as CertificateOfAuthenticityIcon } from '~/static/icons/pdp/certificate-of-authenticity-icon.svg';
export { default as MadeInItalyIcon } from '~/static/icons/pdp/made-in-italy-icon.svg';
// Checkout
export { default as PaymentCreditCardIcon } from '~/static/checkout/payment-credit-card-icon.svg';
export { default as PaymentPaypalIcon } from '~/static/checkout/payment-paypal-icon.svg';
export { default as PaymentEmptyIcon } from '~/static/checkout/payment-empty-icon.svg';
export { default as PaymentGooglePayIcon } from '~/static/checkout/payment-google-pay-icon.svg';
export { default as PaymentApplePayIcon } from '~/static/checkout/payment-apple-pay-icon.svg';
